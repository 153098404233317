import React from "react"
import { Link, graphql } from "gatsby"
import SEOHeader from "../../components/seo-header"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/headerbanner"
import "../../styles/style.scss"
import "../../styles/blog.scss"
import inFeedoLogo from "./img/infeedo-case.jpg"
import Img from "gatsby-image"
class CaseStudyPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <div>
        <SEOHeader
          title="Gofloaters Case Studies"
          description="Explore Gofloaters' insightful case studies showcasing real success stories of businesses and professionals benefiting from flexible workspace solutions."
          socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
          pinterest="true"
        ></SEOHeader>
        <Layout>
          <HeaderBanner>
            <h1>Case Studies</h1>
          </HeaderBanner>

          <div className="container">
            <div className="row">
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                  <div className="col-md-6">
                    <div className="BlogCard" key={node.fields.slug}>
                      <Link to={"/case-studies" + node.fields.slug}>
                        <h4 className="BlogTitle" title={title}>
                          {title}
                        </h4>
                        <Img
                          fluid={
                            node.frontmatter.featuredImage.childImageSharp.fluid
                          }
                          style={{ borderRadius: "16px" }}
                          className="img-responsive"
                          title={title}
                          alt={title}
                        />

                        <br></br>
                        <br></br>
                        <Link
                          className="claimnowButton"
                          to={"/case-studies" + node.fields.slug}
                        >
                          Read More
                        </Link>
                        <br></br>
                      </Link>
                    </div>
                    <br />
                  </div>
                )
              })}
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default CaseStudyPage

export const pageQuery = graphql`
  query CaseStudyPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(case-studies)/" }
        frontmatter: { templateKey: { eq: "case-study" } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date 
            title
            author
            featuredImage {
              relativeDirectory
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
